<template>
  <div class="page">
    <Tables
      ref="xTable"
      :tableName="tableName"
      :tableData="tableData"
      :totalPage="totalPage"
      :isLoading="isLoading"
      :searchFormData="searchFormData"
      :showRefresh="true"
      :showSearch="true"
      @getList="getList"
      @clearSearch="clearSearch"
    >
      <!--搜索自定义内容-->
      <el-form-item slot="search-item">
        <el-input
          v-model="searchFormData.cardno"
          size="small"
          clearable
          placeholder="按卡号查询"
        ></el-input>
      </el-form-item>

      <!--表格内容-->
      <vxe-table-column
        slot="table-item"
        field="log_id"
        title="日志ID"
        align="center"
        min-width="100px"
      />
      <vxe-table-column
        slot="table-item"
        field="cardno"
        title="卡号"
        align="center"
        min-width="100px"
      />
      <vxe-table-column
        slot="table-item"
        field="model"
        title="卡模式"
        align="center"
        min-width="80px"
      >
        <template v-slot="{ row }">
          <span>{{types.model[row.model]||'未知模式'}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column
        slot="table-item"
        field="device_id"
        title="所属设备"
        align="center"
        min-width="100px"
      >
        <template v-slot="{ row }">
          <div v-if="!row.device_id">无</div>
          <div v-if="row.device_id">{{row.device_name}}</div>
          <div class="vxe-sub" v-if="row.device_id">编号:{{row.device_no}}</div>
        </template>
      </vxe-table-column>
      <vxe-table-column
        slot="table-item"
        field="type"
        title="卡类型"
        align="center"
        min-width="80px"
      >
        <template v-slot="{ row }">
          <span>{{types.type[row.type]||'未知类型'}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column
        slot="table-item"
        field="balance"
        title="剩余"
        align="center"
        min-width="80px"
      >
        <template v-slot="{ row }">
          <span>{{row.balance}}{{types.btype[row.type]||''}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column
        slot="table-item"
        field="create_time"
        title="刷卡时间"
        align="center"
        min-width="100px"
      />
    </Tables>
  </div>
</template>

<script>
import Tables from '@/components/tables'
export default {
  name: 'cards-logs-list',
  components: {
    Tables
  },
  data () {
    return {
      // 表格信息
      tableName: '电卡日志',
      isLoading: false,
      tableData: [],
      totalPage: 0,
      searchFormData: {},
      types: {
        model: {
          10: '在线卡',
          20: '离线卡'
        },
        type: {
          10: '金额卡',
          20: '次卡',
          30: '包年包月'
        },
        btype: {
          10: '元',
          20: '次',
          30: ''
        }
      }
    }
  },
  methods: {
    // 表格列表
    async getList (currentPage, pageSize) {
      const params = {
        token: this.$store.state.user.token,
        page: currentPage,
        size: pageSize
      }
      this.isLoading = true
      const searchData = this.searchFormData
      // 小区限制
      const group_id = this.$store.state.user.userInfo.group_id
      if (!searchData.group_id && group_id) {
        searchData.group_id = group_id
      }
      const res = await this.$api.Cards.GetCardsLogs(params, searchData)
      this.tableData = res.data
      this.totalPage = res.total
      this.isLoading = false
    },
    // 重置搜索
    clearSearch () {
      this.searchFormData = {
        u_type: 20
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.row-title {
  font-size: 14px;
  line-height: 20px;
  padding: 4px 0;
  margin-bottom: 18px;

  &:before {
    content: " ";
    width: 5px;
    height: 20px;
    background: #008e4d;
    display: block;
    float: left;
    margin: 0 10px 0 0;
  }
}
</style>
